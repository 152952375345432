import { template as template_0d3004ec579342e4af53b5e60f4b7bb3 } from "@ember/template-compiler";
const FKLabel = template_0d3004ec579342e4af53b5e60f4b7bb3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
