import { template as template_2d6330cc29e54404b14c2842e149ae03 } from "@ember/template-compiler";
const SidebarSectionMessage = template_2d6330cc29e54404b14c2842e149ae03(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
